import React, { useEffect, useState } from "react";
import InputTextField from "../../form/InputTextField";
import { Col, Row} from "react-bootstrap";
import { fieldRequired, phoneNumberPattern } from "../../../config/validations/rules";
import { useForm } from "react-hook-form";
import HTTP from "../../../config/axios";
import { getFormatDDMMYYYY } from "../../../config/utils/dateFunctions";

// Reuse the UserProfile and AuthUser interfaces
interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface userAddress {
  address: string;
  landmark: string;
  evaluationType?: number;
  evaluationDate?: string;
  evaluationTime?: string;
  outletId?: number;
}


// Define the props type for the component
interface VehicleUserInfoProps {
  userProfile: UserProfile;
  userAddress: userAddress
}

const VehicleUserInfo: React.FC<VehicleUserInfoProps> = ({ userProfile, userAddress }) => {
  const { register, handleSubmit, formState: { errors }, setError, setValue, getValues, clearErrors, control, reset, watch } = useForm<Record<string, any>>({ });
  const [outletArray, setOutletArray] = useState<any[]>([])
  const [outletName,setOutletName] = useState<string>("")

  useEffect(() => {
    if (userAddress.evaluationType === 1) {
      reset({
        ...userProfile,
        address: userAddress.address || "",
        landmark: userAddress.landmark || "",
      });
    } else if (userAddress.evaluationType === 2) {
      reset({
        ...userProfile,
        evaluationDate: userAddress.evaluationDate
          ? getFormatDDMMYYYY(userAddress.evaluationDate)
          : "",
        evaluationTime: userAddress.evaluationTime || "",
        outletName: outletName, 
      });
    } else {
      reset({
        ...userProfile,
      });
    }
  }, [userProfile, userAddress, outletName]);


  useEffect(() => {
    try {
      const getOutlet = async () => {
        const locationData = await HTTP.get(`/api/location/outlet/fetch-all`, { params: { status: 1 } });
        const { data: { status, data, msg, error } } = locationData

        if (status == 1) {
          console.log("data.rows",data.rows)
          setOutletArray(data.rows)
          const outletName = data.rows.find((outlet:any) => outlet.id === userAddress.outletId)?.name || "";

          setOutletName(outletName)
        } else {
          setOutletArray([])
        }
      }
      getOutlet()
    }
    catch (err) {

    }
  }, [])

  return (
    <>
      <h1>Seller Info</h1>
    <Row className="mt-4">
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="firstName"
              label="First Name"
              placeholder="First name"
              disabled={true}
              clearErrors={clearErrors}
              control={control}
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="lastName"
              label="Last Name"
              placeholder="Last name"
              clearErrors={clearErrors}
              disabled={true}
              control={control}
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              clearErrors={clearErrors}
              type="number"
              control={control}
              disabled={true} // Disables the phone input field
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="email"
              label="Email address"
              placeholder="Enter your email"
              clearErrors={clearErrors}
              disabled={true}
              control={control}
            />
          </div>
        </Col>
        {/* <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="address"
              label="address"
              placeholder="address "
              clearErrors={clearErrors}
              type="string"
              control={control}
              disabled={true} // Disables the phone input field
            />
          </div>
        </Col>
        <Col lg={6} md={6}>
          <div className="mb-3">
            <InputTextField
              name="landmark"
              label="landmark"
              placeholder="landmark"
              clearErrors={clearErrors}
              type="string"
              control={control}
              disabled={true} // Disables the phone input field
            />
          </div>
        </Col> */}
        {userAddress.evaluationType === 1 ? (
          <>
            <Col lg={6} md={6}>
              <div className="mb-3">
                <InputTextField
                  name="address"
                  label="Address"
                  placeholder="Address"
                  disabled={true}
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="mb-3">
                <InputTextField
                  name="landmark"
                  label="Landmark"
                  placeholder="Landmark"
                  disabled={true}
                  control={control}
                />
              </div>
            </Col>
          </>
        ) : userAddress.evaluationType === 2 ? (
          <>
            <Col lg={6} md={6}>
              <div className="mb-3">
                <InputTextField
                  name="evaluationDate"
                  label="Evaluation Date"
                  placeholder="Evaluation Date"
                  disabled={true}
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="mb-3">
                <InputTextField
                  name="evaluationTime"
                  label="Evaluation Time"
                  placeholder="Evaluation Time"
                  disabled={true}
                  control={control}
                />
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="mb-3">
                <InputTextField
                    name="outletName"
                    label="Outlet Name"
                    placeholder="Outlet Name"
                  disabled={true}
                  control={control}
                />
              </div>
            </Col>
          </>
        ) : (
          <Col lg={12} md={12}>
            <p className="text-danger"></p>
          </Col>
        )}

    </Row>
    </>
  );
};

export default VehicleUserInfo;
