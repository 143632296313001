import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import HTTP from "../../config/axios";
import { useForm } from 'react-hook-form';
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { fieldRequired, phoneNumberPattern, emailPattern, minLength, maxLength, validateDob, pinCodePattern, validatePAN } from '../../config/validations/rules';
import InputTextField from "../../components/form/InputTextField";
import DropDownField from "../../components/form/DropDownField";
import SimpleToast from "../../components/common/Toast";
import { encryptLocalStorage } from "../../config/utils/storage";
import { useLocation, useNavigate } from "react-router-dom";

const ReferAndEarn = () => {
    const defaultFormValues = {
        name: "",
        phone: "",
        stateId: "",
        districtId: "",
        referType: ""
    }

    const [isMobile, setIsMobile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false)
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { control, handleSubmit, formState: { errors }, clearErrors, setError, watch, setValue, reset, getValues } = useForm<Record<string, any>>({});
    const referOptions = [
        { id: 1, name: "Buy", keyname: "buy" },
        { id: 2, name: "Sell", keyname: "sell" }
    ]
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    
    const currentLocation = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);
    useEffect(() => {
        try {
            const getAllStates = async () => {
                const response = await HTTP.post(`/api/booking/states/fetch-all`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setStates(data)
                }
            }
            getAllStates()
        }
        catch (error) {
            console.log("Error in states fetch API", error)
        }
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 565);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const selectedStateId = watch('stateId')
    useEffect(() => {
        try {
            setValue('districtId', ''); setDistricts([])
            const getAllDistrict = async () => {
                const response = await HTTP.post(`/api/booking/districts/fetch-all/${selectedStateId}`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setDistricts(data)
                }
            }
            selectedStateId && getAllDistrict()
        }
        catch (error) {
            console.log("Error in states fetch API", error)
        }
    }, [selectedStateId]);
    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            const response = await HTTP.post(`/api/add-referral`, data);
            if (response.data.status === 1) {
                setSuccessMsg(true);
                setToast({ open: true, header: 'Success', message: 'Referral added successfully', variant: 'success' })
            } else {
                setError(response.data.type ? response.data.type : "serverError", {
                    type: "manual", message: response.data.message,
                });
                setToast({ open: true, header: 'Failed', message: response.data.message, variant: 'danger' })

            }
        }
        catch (err) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            setIsLoading(false);
            reset(defaultFormValues);
            setIsLoading(false);
        }

    }

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/refer_and_earn_banner.jpg`;
    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "Refer And Earn", path: "/refer-and-earn", active: false, linkClass: "breadcrumb-text" },
        { title: "Refer A Friend", path: "/refer-a-friend", active: true, linkClass: "text-primary fw-semibold" }
    ]

    const handleSignIn = () => {
        encryptLocalStorage.setItem("previousLoggedoutLocation", currentLocation.pathname)
        navigate("/sign-in")
    }

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
            try {

                const fetchUser = async () => {
                    const response = await HTTP.get(`/api/fetch-profile`);
                    const { data: { status, data, msg, error } } = response
                    if (status == 1) {
                        setValue('name', `${data.firstName} ${data.lastName}`);
                        setValue('phone', data.phone)
                        setValue('email', data.email)
                    }
                }
                fetchUser()
            }
            catch (error) {
                console.log("Error in states fetch API", error)
            }
        }

    }, [])


    return (
        <>

            <Container fluid className="top-banner-small-fit d-flex mt-90" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto">
                    <Col md={12}>
                        <h3 className="text-primary mb-1">Refer your friends & get rewarded</h3>
                        <h2 className="text-light">Loving the Popular experience?</h2>
                        {/* <p className="text-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis massa aliquam lectus et nulla sit quis. Dignissim urna aliquet vestibulum
                            sed urna, quis augue risus rhoncus.</p> */}
                    </Col>
                </Container>

            </Container >

            <Breadcrumbs breadcrumbData={breadCrumbList} />
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="py-3">

                        <Col md={12} >
                            <div className="text-center pb-4">
                                <h2 className="text-primary head-bold">REFER YOUR FRIEND</h2>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-1">
                                <Row className="g-3">
                                    <Col md={12}>
                                        <InputTextField name="name" label="Name" control={control}
                                            clearErrors={clearErrors} rule={{ required: fieldRequired }} placeholder=" Enter Name " />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Row className="g-3">
                                <Col md={12}>
                                    <InputTextField name="phone" type="tel" max={10}
                                        label="Phone number" control={control}
                                        rule={{ required: fieldRequired, pattern: phoneNumberPattern }} placeholder=" Enter ph.no " />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <DropDownField name="stateId" label="State"
                                    options={states} placeholder=" State name "
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <DropDownField name="districtId" label="District"
                                    options={districts} placeholder="District name"
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <DropDownField name="referType" label="Type"
                                    options={referOptions} placeholder="Choose type"
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                        </Col>
                        <Row className={`${isMobile ? 'text-center' : 'justify-content-end'} pe-0`}>
                            {isLoggedIn ? (
                                <Button className="col-md-2 mx-2" variant="primary" type="submit">
                                    Submit
                                </Button>
                            ) : (
                                <Button variant="primary  col-md-2 mx-2" onClick={handleSignIn}>
                                    Contact&nbsp;us
                                </Button>
                            )}
                        </Row>
                    </Row>
                </Form>
            </Container >
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default ReferAndEarn;