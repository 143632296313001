import { faAngleRight, faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Card, Row, Form, Button, Table } from "react-bootstrap";
import ContactUs from "../../../components/common/ContactUs";
import { useLocation } from "react-router";
import { useEffect } from "react";
const Checkout = () => {

 const { pathname } = useLocation();
      useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" })
  }, [pathname]);
 

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/popular_journeys_banner.jpg`;

    return (
        <div className="App">
            <Container fluid className="top-banner-small-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto mt-5">
                    <Col md={12}>
                        <h3 className="text-primary">The sure road to Car Joy</h3>
                        <h2 className="text-light mb-1">
                            Popular ensures that car ownership is delightful, accessible
                            and simple for all.
                        </h2>
                        {/* <p className="text-light is-description">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis
                            massa aliquam lectus et nulla sit quis. Dignissim urna aliquet
                            vestibulum sed urna, quis augue risus rhoncus.
                        </p> */}
                        <Button variant="primary" type="submit">
                            Watch the film <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                    </Col>
                </Container>
            </Container>
            <Container>
                <Row>
                    <Col md={8} className="mt-5">
                        <h3 className="text-primary mb-0">One year warranty</h3>
                        <h3 className="is-testimonials text-dark">
                            There for you, when you need us
                        </h3>
                        {/* <p className="pb-3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis
                            mattis a eu, tristique et euismod quis magna. Commodo metus massa,
                            tellus aenean pellentesque pharetra. Consectetur eu cursus diam
                            vulputate viverra sed neque porttitor tellus. Aliquet fringilla
                            erat sem porttitor diam hac.
                        </p> */}
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={8} className="order-sm-2 order-1 order-md-1 order-lg-1">
                        <Table bordered>
                            <tbody className="align-middle">
                                <tr>
                                    <td width={"20%"} rowSpan={2} className="text-primary">
                                        Warranty coverage Overview
                                    </td>
                                    <td className="text-primary">Comprehensive Coverage</td>
                                    <td className="text-primary">Powertrain Coverage</td>
                                </tr>
                                <tr>
                                    <td className="text-secondary">
                                        Applicable for 90 days or upto 5,000 kms from the date
                                        ofpurchase (whichever occursfirst)
                                    </td>
                                    <td className="text-secondary">
                                        Applicable for 365 days orupto 15,000 kms from the date of
                                        purchase (whichever occurs first)
                                    </td>
                                </tr>
                                <tr>
                                    <td>Engine & Peripherals</td>
                                    <td className="text-success">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </td>
                                    <td className="text-success">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Transmission</td>
                                    <td className="text-success">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </td>
                                    <td className="text-success">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Steering System</td>
                                    <td className="text-success">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </td>
                                    <td className="text-danger">
                                        <FontAwesomeIcon icon={faCircleXmark} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Braking System</td>
                                    <td className="text-success">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </td>
                                    <td className="text-danger">
                                        <FontAwesomeIcon icon={faCircleXmark} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Air Conditioning</td>
                                    <td className="text-success">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </td>
                                    <td className="text-danger">
                                        <FontAwesomeIcon icon={faCircleXmark} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="pb-4">
                            <Button variant="primary" type="submit">
                                Warranty details <FontAwesomeIcon icon={faAngleRight} />
                            </Button>
                        </div>
                    </Col>
                    <Col md={4} className="order-sm-1 order-md-2 order-lg-2 mb-3 px-3">
                        <iframe
                            title="Click to access youtube link 1"
                            width={"100%"}
                            height={"350px"}
                            src="https://www.youtube.com/embed/hkltp7YIH48"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col md={4} className="d-flex align-items-center px-3">
                        <iframe
                            title="Click to access youtube link 2"
                            width={"100%"}
                            height={"350px"}
                            src="https://www.youtube.com/embed/hkltp7YIH48"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </Col>
                    <Col md={8} className="">
                        <h4 className="text-primary mb-0 text-md-end text-sm-center text-lg-end pt-3">3 day money back guarantee</h4>
                        <h3 className="is-testimonials text-dark mb-0 text-md-end text-sm-center text-lg-end">
                            Love it or leave it.
                        </h3>
                        <p>
                            If you are not completely satisfied with your pre-owned car purchase, just bring it back, its that simple.
                        </p>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col md={8}>
                        <h4 className="text-primary mb-0">Fixed price assurance</h4>
                        <h3 className="is-testimonials text-dark mb-0">
                            A Fair Price
                        </h3>
                        <p>
                            You can always buy at a fair price. Assuring value for money deals.
                        </p>
                    </Col>
                    <Col md={4} className="d-flex align-items-center px-3">
                        <iframe
                            title="Click to access youtube link 3"
                            width={"100%"}
                            height={"350px"}
                            src="https://www.youtube.com/embed/hkltp7YIH48"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </Col>
                </Row>
            </Container>
            <hr />
            <Container>
                <Row className="text-center mt-5">
                    <h4 className="text-primary mb-0">Introducing buyback </h4>
                    <h3 className="text-dark is-testimonials">
                        Commit less, drive more.
                    </h3>
                </Row>
                <Row className="mb-4">
                    <Col md={5} className="d-flex align-items-center px-3">
                        <iframe
                            title="Click to access youtube link 4"
                            width={"100%"}
                            height={"250px"}
                            src="https://www.youtube.com/embed/hkltp7YIH48"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </Col>
                    <Col md={7}>
                        <p>

                        </p>
                        <div className="pb-4">
                            <Button variant="primary" type="submit">
                                View benefits <FontAwesomeIcon icon={faAngleRight} />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ContactUs />
        </div>
    );
}

export default Checkout;