import React, { useCallback, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SignIn from "../../../components/modules/Authentication/SignIn";
import SignUp from "../../../components/modules/Authentication/SignUp";
import { NavLink, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import HTTP from "../../../config/axios";
import { encryptLocalStorage } from "../../../config/utils/storage";

const SignInSignUp = () => {



  const [activeTab, setActiveTab] = useState("signup");
  const [siteKey, setSiteKey] = useState('');
  const [siteToken, setSiteToken] = useState('');
  const [signUpCms, setSignUpCms] = useState("");
  const [signInCms, setSignInCms] = useState("");
  const navigate = useNavigate()


  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    const userInfo = encryptLocalStorage.getItem("userInfo")
    
    if (token && userInfo) {
      navigate('/')
    }
  },[])

  useEffect(() => {
    const SignInSignUpCms = async () => {
      const cmsData = await HTTP.post(`/api/cms/fetch-all`,
        {
          nameList: ["sign-in", "sign-up"]
        })
      const { data: { status, data, msg, error } } = cmsData;

      data.forEach((cmsContent: any) => {
        if (cmsContent.name === "sign-in") {
          setSignInCms(cmsContent.content);
        } else if (cmsContent.name === "sign-up") {
          setSignUpCms(cmsContent.content);
        }
      })
    }

    SignInSignUpCms()
  }, []);

  useEffect(() => {
    const getSiteKey = async () => {
      try {
        const response = await HTTP.get('/api/fetch-site-key')
        const { status, siteKey } = response.data
        if (status && siteKey) {
          setSiteKey(siteKey)
        }
      }
      catch (err) {
        console.log('Check Error from get site key', err)
      }
    }
    // encryptLocalStorage.clear()
    getSiteKey()
  }, [])


  const handleVerify = useCallback((token: string) => {
    setSiteToken(token);
  }, [])

  return (
    <div className="App">
      {siteKey &&
        <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
          <Container fluid>
            <Row className="vh-100">
              <Col xs={12} md={6} lg={8} className="p-0 ">
                <div className=" signin-banner h-100 d-none d-md-block d-lg-block ">
                  {activeTab == "signup" ? (
                    <div className=" sign-banner-text" dangerouslySetInnerHTML={{ __html: signUpCms && signUpCms }}>

                    </div>
                  ) :
                    <div className=" sign-banner-text" dangerouslySetInnerHTML={{ __html: signInCms && signInCms }}>
                    </div>}
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className="p-5 overflow-auto vh-100">
                <div className=" mt-60 text-center">
                  <NavLink to="/">
                    <Image
                      className="align-self-center mt-4 "
                      src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/pvsLogo.svg`}
                      width={"70%"}
                    ></Image>
                  </NavLink>
                  {/* <p className="text-center fs-7 mt-3">
                    Lorem ipsum dolor sit amet, consectetur <br></br>
                    adipiscing elit Enim.
                  </p> */}
                </div>
                <Tabs
                  activeKey={activeTab}
                  onSelect={(key: string | null) => setActiveTab(key ?? "signup")}
                  transition={false}
                  id="noanim-tab-example"
                  className="mt-5 mb-3"
                >
                  <Tab eventKey="signin" title="Sign in">
                    {activeTab == "signin" && <SignIn />}
                  </Tab>
                  <Tab eventKey="signup" title="Sign up">
                    {activeTab == "signup" && <SignUp setActiveTab={setActiveTab} />}
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </GoogleReCaptchaProvider>
      }

    </div>
  );
}

export default SignInSignUp;
