import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Faq from "../../components/common/Faq";
import ContactUs from "../../components/common/ContactUs";
import { encryptLocalStorage } from "../../config/utils/storage";
import { useNavigate } from "react-router-dom";

const HowPopularWorks = () => {
    let locationName = encryptLocalStorage.getItem("selectedLocation");
    const navigate = useNavigate();

    const handleAssuredCars = async () => {
        const searchCriteria = { assuredList: 1 }
        navigate(`/buy-used-car?search-criteria=${JSON.stringify(searchCriteria)}`)
    }

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/how_popular_works_banner.jpg`;

    return (
        <>
            <Container fluid className="top-banner-class-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto">
                    <Col md={12}>
                        <h3 className="text-primary mb-1">How popular works</h3>
                        <h2 className="text-light">We are about car joy - from selection to every part of ownership</h2>
                        <p className="text-light">Whether it's a first set of wheels you're after, or something bigger for everybody in the family or that big upgrade, with us you'll be a
                            happy car owner, confident about your purchase</p>
                        <Button variant="primary" type="submit" onClick={handleAssuredCars}>
                            See assured cars <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                    </Col>
                </Container>
            </Container>
            <Container>
                <Row className="mt-4 ">
                    <Col md={12}>
                        <div className="text-center pb-4">
                            {/* <h3 className="text-primary ">Lorem Ipsum dolar</h3> */}
                            <h2 className="text-dark ">Buying your popular assured car</h2>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/choose.svg`} width={"80%"} />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">Choose from our handpicked cars at popular</h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Eget risus feugiat.</p> */}
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/01.svg`} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/02.svg`} />
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-end text-sm-end">
                            <h3 className="text-dark fw-normal fs-5">Get into every detail</h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Eget risus feugiat.</p> */}
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/search-detail.svg`} width={"80%"} />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/test-drive.svg`} width={"80%"} />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">Your test drive, your way</h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Eget risus feugiat.</p> */}
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/03.svg`} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/04.svg`} />
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-end text-sm-end">
                            <h3 className="text-dark fw-normal fs-5">Paperwork and financing, handled</h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Eget risus feugiat.</p> */}
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/paperwork.svg`} width={"80%"} />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/complete-payment.svg`} width={"80%"} />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">Complete all payments, online</h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Eget risus feugiat.</p> */}
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/05.svg`} />
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md={2} className="align-self-center d-none d-d-md-block d-lg-block">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/06.svg`} />
                    </Col>
                    <Col md={2} className="align-self-center d-none d-d-md-block d-lg-block">
                        <hr />
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="align-self-center">
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">Delivery Day - a day to remember</h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Eget risus feugiat.</p> */}
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/ok.svg`} width={"80%"} />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Faq />
            <ContactUs />
        </>
    );
}

export default HowPopularWorks;