import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import HTTP from "../../config/axios";
import Faq from "../../components/common/Faq";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { Link } from "react-router-dom";

const ReferAndEarn = () => {
    interface IReferAndEarn {
        id: number;
        title: string;
        name: string;
        content: string;
    }

    const [cmsContent, setCmsContent] = useState<IReferAndEarn[]>([]);
    useEffect(() => {
        const getReferAndEarn = async () => {
            const referAndEarnData = await HTTP.post(`/api/cms/fetch-all`,
                {
                    nameList: ["refer-and-earn"]
                })
            const { data: { status, data, msg, error } } = referAndEarnData
            if (status == 1) {
                setCmsContent(data)
            } else {
                setCmsContent([])
            }
        }
        getReferAndEarn()
    }, []);

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/refer_and_earn_banner.jpg`;
    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "Refer And Earn", path: "/refer-and-earn", active: true, linkClass: "text-primary fw-semibold" }
    ]

    return (
        <>

            <Container fluid className="top-banner-small-fit d-flex mt-90" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto">
                    <Col md={12}>
                        <h3 className="text-primary mb-1">Refer your friends & get rewarded</h3>
                        <h2 className="text-light">Loving the Popular experience?</h2>
                        <p className="text-light">Love our service? Share it with your friends and family! Refer someone to us and earn rewards when they complete their transaction. It’s simple: refer, earn, and enjoy the benefits. Start sharing now and turn your referrals into rewards!</p>
                        <Link to="/refer-and-earn/refer-a-friend"> <Button variant="primary" type="submit" >
                            Start referring <FontAwesomeIcon icon={faAngleRight} />
                        </Button></Link>
                    </Col>
                </Container>
            </Container >

            <Breadcrumbs breadcrumbData={breadCrumbList} />
            <Container>
                {cmsContent.map((fqaData) => (

                    <Row className="text-dark label" key={fqaData.id}>
                        <div dangerouslySetInnerHTML={{ __html: fqaData.content }}></div>
                    </Row>
                ))}
                {/* <Row className="my-5 refer-and-earn">   
                    <Col md={6} className="border-end border-end-sm-0">
                        <h3 className="text-primary mb-1">Being a Good Friend Pays, Here's How</h3>
                        <ol className="list-numbered text-dark ps-3">
                            <li>
                                <div>Refer a friend(s) to Popular </div>
                                <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam at
                                    sed felis faucibus et arcu</p>
                            </li>
                            <li>
                                <div>Your friend completes a sell/buy</div>
                                <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam at
                                    sed felis faucibus et arcu</p>
                            </li>
                            <li>
                                <div>You both earn rewards</div>
                                <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam at
                                    sed felis faucibus et arcu</p>
                            </li>
                        </ol>
                    </Col>
                    <Col md={6}>
                        <h3 className="text-primary mb-1">Track Your Rewards</h3>
                        <ol className="list-numbered text-dark ps-3">
                            <li>
                                <div>Stay updated on your rewards</div>
                                <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam at
                                    sed felis faucibus et arcu</p>
                            </li>
                            <li>
                                <div>Refer more, earn more</div>
                                <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam at
                                    sed felis faucibus et arcu</p>
                            </li>
                            <li>
                                <div>Redeem your rewards</div>
                                <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam at
                                    sed felis faucibus et arcu</p>
                            </li>
                        </ol>
                    </Col>
                </Row>
                <hr /> */}
                <hr />
            </Container>
            <Faq />
        </>
    );
}

export default ReferAndEarn;